import React from 'react';
import { FaTwitter, FaDiscord } from "react-icons/fa"

const TwitterLink = () => {
    const twitterUsername = 'AnimezkGirlsNFT';
    const twitterUrl = `https://twitter.com/${twitterUsername}`;

    return (
      <span className="link">
            <a href={twitterUrl} target="_blank" rel="noopener noreferrer">
                <FaTwitter size="3em" color='lightblue'/> {/* アイコンのサイズを変更したい場合は、sizeプロパティを調整してください */}
            </a>
        </span>
    );
};

const DiscordLink = () => {
    const discordInviteLink = 'https://discord.gg/Yc99kZ23zy';

    return (
      <span className="link">
        <a href={discordInviteLink} target="_blank" rel="noopener noreferrer">
          <FaDiscord size="3em" color='white'/> {/* アイコンのサイズを変更したい場合は、sizeプロパティを調整してください */}
        </a>
      </span>
    );
}

export { TwitterLink, DiscordLink };