import logo from './logo.svg';
import './App.css';
import { PolygonZkevmTestnet } from "@thirdweb-dev/chains";
import { ThirdwebProvider, useContract } from "@thirdweb-dev/react";

import ConnectWalletButton from './ConnectWalletButton.js'
import MintButton from './Mint.js'
import Mintembed from './Mintembed.js'

import {TwitterLink,DiscordLink} from './Link.js'


function App() {
  return (
    <ThirdwebProvider activeChain={PolygonZkevmTestnet}>
      <Component />

      <div className="link-wrapper">
        <TwitterLink/><DiscordLink/>
      </div>
      <Mintembed/>

    </ThirdwebProvider>
  )
}

function Component() {
  const { contract, isLoading } = useContract("0xd720932718EC8330Fc41A3ef328bf6209DD6cC5a");
}

export default App;
