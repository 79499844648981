import React from 'react';
import Iframe from 'react-iframe'
function App() {
    return (
        <div className="container">
            <Iframe
                src="https://ipfs.thirdwebcdn.com/ipfs/QmbAgC8YwY36n8H2kuvSWsRisxDZ15QZw3xGZyk9aDvcv7/erc721.html?contract=0x9d3cEA2416877414c33e2a80E2D46f535321f2ef&chain=%7B%22name%22%3A%22Polygon+zkEVM%22%2C%22chain%22%3A%22Polygon%22%2C%22rpc%22%3A%5B%22https%3A%2F%2Fpolygon-zkevm.rpc.thirdweb.com%2F5a9bc94b87f7cbbbfbbc234bf1e07f0adf5f3cf3012c9f26f9fc9820d64df93a%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22Ether%22%2C%22symbol%22%3A%22ETH%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22zkevm%22%2C%22chainId%22%3A1101%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22polygon-zkevm%22%7D&theme=dark&primaryColor=pink"
                width='600px'
                height='600px'
                frameborder="0"
            ></Iframe>
        </div>
    );
}
export default App;